<template>
  <DsPageContent>
    <DsHero
      v-if="content.header?.title"
      :title="content.header.title"
      :links="content.header.links"
      theme="dark"
    />
    <InvestorRelationsCollapsibleBlock
      v-if="content.financialsTitle && content.financialItems"
      :title="content.financialsTitle"
    >
      <template #default="{ isOpen }">
        <InvestorRelationsFinancialBlockCollection
          :financial-data="content.financialItems"
          :year="content.financialYear"
          :show-line-chart="isOpen"
          theme="dark"
        />
      </template>
    </InvestorRelationsCollapsibleBlock>

    <DsSectionImage
      v-if="content.mediaAsset"
      theme-variant="dark-light"
    >
      <div class="ds-grid">
        <div class="ds-grid__col">
          <DsVideoPlayer
            v-if="content.mediaAsset.componentName === 'DsVideoPlayer'"
            v-bind="content.mediaAsset.content"
            class="ds-width-full"
            :screen="screen"
          />
          <DsImageElement
            v-if="content.mediaAsset.componentName === 'DsImageElement'"
            :image="content.mediaAsset.content"
            class="ds-width-full"
            :screen="screen"
          />
        </div>
      </div>
    </DsSectionImage>
    <DsDivider v-if="content.faq" />
    <InvestorRelationsPressReleases
      v-if="content.news && content.news.length"
      :title="content.newsTitle"
      :cards="content.news"
      :link="content.seeAllNewsLink"
    />
    <template v-if="content.events">
      <DsDivider />
      <!--    <ClientOnly>-->
      <DsSection v-if="content.events.items.length">
        <div class="ds-grid">
          <div class="ds-grid__col">
            <EventsListSection
              :title="content.events.label"
              :items="content.events.items"
            />
            <DsLink
              v-if="content.seeAllEventsLink"
              class="ds-margin-top-12 ds-md-margin-top-24 link-full-width"
              :content="content.seeAllEventsLink.title"
              :url="content.seeAllEventsLink.url"
              :open-in-new-window="false"
              :nuxt="true"
              type="button"
              variant="secondary"
            />
          </div>
        </div>
      </DsSection>
      <!--    </ClientOnly>-->
    </template>
    <DsSection v-if="content.faq">
      <FaqGroup
        v-bind="content.faq"
        class="ds-container ds-margin-y-48 ds-md-margin-y-96"
      />
    </DsSection>
    <ClientOnly>
      <NewsletterForm
        v-if="data?.newsletterFormData"
        v-bind="data.newsletterFormData"
      />
    </ClientOnly>
  </DsPageContent>
</template>
<script setup>
import {
  DsPageContent,
  DsHero,
  DsSectionImage,
  DsVideoPlayer,
  DsImageElement,
  DsSection,
  DsDivider,
  DsLink
} from 'awds'
import FaqGroup from '../../core/components/faq-group/faq-group.vue'
import { queryPageInvestors, queryFinancialsFinancialRecapCollection } from '../data-layer/queries'
import FORM_NAME from '../../core/constants/globalForm'
import InvestorRelationsFinancialBlockCollection from '~/components/investor-relations-financial-block-collection.vue'

const { labels, localeGlobalContentData } = useInvestorsGlobalData()
const { getSlug } = usePageUtils()
const { getLocaleIso } = useLocales()
const CURRENT_DATE = new Date()
const CURRENT_YEAR = CURRENT_DATE.getFullYear()
const { queryContentful } = useQueries()
const { mappers } = useInvestorsDataLayer()
const { dataPageMapper } = useDataMapper()
const route = useRoute()
const { locale: localeRef } = useI18n()
const slug = getSlug(route.path) || 'investor-relations'
const locale = getLocaleIso(localeRef.value)
const { screen } = useScreen()
const { content, onBeforeAdyenData, onAfterAdyenData } = useInvestorsPageData()

await onBeforeAdyenData({
  withRoutes: true,
  globalDataContentType: 'PageInvestorRelations'
})

const { data } = await useAsyncData('investor-relations-' + localeRef.value, async () => {
  const investorsQuery = queryContentful(
    {
      query: queryPageInvestors,
      variables: { slug, locale, startDate: CURRENT_DATE.toISOString(), preview: useRuntimeConfig().public.preview }
    },
    { log: false }
  )

  // Get financials in a separate query because data is too complex for 1 page query
  const financialsQuery = queryContentful(
    {
      query: queryFinancialsFinancialRecapCollection,
      variables: { year: CURRENT_YEAR, preview: useRuntimeConfig().public.preview }
    },
    { log: false }
  )

  const [investorsData, financialsData] = await Promise.all([investorsQuery, financialsQuery])

  if (!investorsData || !financialsData) {
    return {
      pageData: {},
      newsletterFormData: {}
    }
  }
  const entry = investorsData.page.items[0]
  const news = investorsData.news.items
  const events = investorsData.events.items
  const pageData = dataPageMapper(
    {
      ...entry,
      ...{ news },
      ...{ events },
      ...{
        financials: {
          year: CURRENT_YEAR,
          fullYearData: financialsData.financialsFullYear,
          halfYearData: financialsData.financialsHalfYear
        }
      },
      ...{ labels: labels.value }
    },
    mappers
  )

  return {
    newsletterFormData: localeGlobalContentData.value?.globalForms?.[FORM_NAME.newsletterInvestorRelations],
    pageData
  }
})

await onAfterAdyenData(data.value)
</script>
